/**
 * Our Video-block
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';

// Peritus
import ResponsiveImage from 'components/ResponsiveImage/ResponsiveImage';
import Block from 'components/Block';
import VideoEmbed from 'components/VideoEmbed';

// Styles
import * as styles from './video.module.scss';

// Assets
import TriangleIcon from './triangle.inline.svg';

const Video = ({videoType, videoUrl, image, video, ...props}) => {
    let [videoEl, setVideo] = React.useState(null);
    let videoSrc = video
        ? video.gatsbyFileUrl
            ? video.gatsbyFileUrl
            : (video.mediaItemUrl ? video.mediaItemUrl : '#')
        : '#';

    const callback = (ev) => {
        ev.preventDefault();

        let target = ev.currentTarget;
        let videoAttr = {};
        videoAttr.height = target.clientHeight;
        videoAttr.width = target.clientWidth;

        const videoEl = 'embed' === videoType
            ? <VideoEmbed url={videoUrl} {...videoAttr}/>
            : <video className={styles.videoElement} {...videoAttr} controls autoPlay={true}>
                <source src={videoSrc} type={video.mimeType}/>
            </video>;

        setVideo(videoEl);
    };

    return (
        <Block {...props}>
            {!videoEl && <a
                href={'embed' === videoType ? videoUrl : videoSrc}
                onClick={callback}
                className={styles.videoLink}
                target={'_blank'}
                rel={'noopener nowfollow noreferrer'}
            >
                <ResponsiveImage
                    className={styles.previewImage}
                    image={image}
                    data-image/>
                <TriangleIcon className={styles.triangle}/>
            </a>}
            {videoEl}
        </Block>
    );
};

export default Video;
