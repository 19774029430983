/**
 * Our layout-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';

// Peritus
import Block, {BlockShape} from 'components/Block';

// Styles
// import * as styles from './layout.module.scss';
import {full} from 'components/Block/block.module.scss';

const Layout = ({layouts, Blocks, ...props}) => (<Block className={full} {...props}>
    {layouts && layouts.map((block, key) => (<Blocks key={key} block={block}/>))}
</Block>);

Layout.propTypes = {
    ...BlockShape,
    layouts: PropTypes.array.isRequired
};

export default Layout;
